<template>
    <div class="content" :style="{ maxHeight: maxHeight + 'px' }" ref="imgPage">
        <imageItem v-for="(item, index) in imgList" :key="index" :info="item"></imageItem>
    </div>
</template>

<script>
import imageItem from './imageItem.vue'
export default {
  name: 'ImagePageItem',
  components: {
    imageItem
  },
  props: {
    imgList: {
      default: () => [],
      type: Array
    },
    maxHeight: {
      default: 500,
      type: Number
    }
  },
  data () {
    return {

    }
  },
  methods: {
    // 判断滚动条是否置底
    checkScroll () {
      const container = this.$refs.imgPage
      // 被滚动条卷去的高度
      const scrollTop = container.scrollTop
      // 盒子内容可见范围的高度
      const scrollHeight = container.scrollHeight
      // 盒子总的高度 (包含隐藏区域)
      const clientHeight = container.clientHeight

      // 判断是否触底
      if (Math.ceil(scrollTop + clientHeight) >= scrollHeight) {
        this.$emit('scrollBottom')
      }
    }
  },
  mounted () {
    // 绑定监听
    this.$refs.imgPage.addEventListener('scroll', this.checkScroll)
  },
  beforeDestroy () {
    // 移除监听
    this.$refs.imgPage.removeEventListener('scroll', this.checkScroll)
  },
  created () {

  }
}
</script>

<style lang="scss" scoped>
.content {
    padding-top: 10px;
    background: #ccc;
    // 设置 grid 布局
    display: grid;
    // 设置未定义行高的网格项目的默认行高
    grid-auto-rows: 5px;
    // 设置 Grid 布局中的网格列的模板
    grid-template-columns: repeat(auto-fill, calc(20% - 5px));
    // 次轴对齐方式 顶部对齐
    align-items: start;
    // 主轴对齐方式 平铺且每行左右两侧不留空
    justify-content: space-between;
    overflow: auto;
}

@media (max-width:1040px) {
    .content {
        grid-template-columns: repeat(auto-fill, calc(33.3% - 5px));
    }
}

@media (max-width:840px) {
    .content {
        grid-template-columns: repeat(auto-fill, calc(50% - 5px));
    }
}

@media (max-width:640px) {
    .content {
        grid-template-columns: repeat(auto-fill, calc(100% - 5px));
    }
}
</style>
