<template>
  <div ref="root" class="imgItem">
    <img :src="info.urls.original" ref="imgRef">
    <a :href="info.urls.original" target="_blank" download>下载图片</a>
    <h4>{{ info.title }}</h4>
    <p>作者:{{ info.author }}</p>
    <p>pid:{{ info.pid }}</p>
  </div>
</template>

<script>
import errImg from '@/assets/err.png';
export default {
  props: {
    info: Object
  },
  data() {
    return {
      errImg,
      timer: null,
    }
  },
  methods: {
    // 调整内容在 Grid 盒子下的份额
    handleResize() {
      this.$nextTick(() => {
        // 确定已成功获取 DOM 节点
        // 当组件销毁时 可能仍然在进行计算尺寸 需要进行判断
        if (this.$refs.root) {
          const el = this.$refs.root
          const rows = Math.ceil(el.clientHeight / 5) + 2
          el.style.gridRowEnd = `span ${rows}`
        }
      })
    },
    // 图片加载失败
    imgErrorFn() {
      this.$nextTick(() => {
        if (this.$refs.root) {
          this.$refs.imgRef.src = this.errImg;
        }
        this.handleResize();
      })
    },
    // 周期性检查尺寸
    cycleChangeImgItemBoxFn() {
      this.timer = setInterval(() => {
        this.handleResize()
      }, 500);
    },
    // 图片加载完成事件处理
    imgLoadChangeSizeFn() {
      this.$nextTick(() => {
        this.$refs.imgRef.addEventListener('load', () => {
          console.log('清除周期任务');
          clearInterval(this.timer)
          this.handleResize();
        })
        this.$refs.imgRef.addEventListener('error', () => {
          console.log('清除周期任务');
          clearInterval(this.timer)
          this.imgErrorFn()
        })
      })
    }
  },
  mounted() {
    // 监听页面尺寸改变事件
    window.addEventListener('resize', this.handleResize)
  },
  watch: {
    info: {
      immediate: true,
      handler() {
        // 初始化结构
        this.handleResize()
        // 周期性检查尺寸
        this.cycleChangeImgItemBoxFn();
        // 绑定图片加载完成后修改尺寸
        this.imgLoadChangeSizeFn()
      }
    }
  },
  beforeDestroy() {
    // 清除绑定事件
    window.removeEventListener('resize', this.handleResize)
    this.$refs.imgRef.removeEventListener('load', this.handleResize)
    this.$refs.imgRef.removeEventListener('error', this.imgErrorFn)
    this.$refs.imgRef.src = '';
    clearInterval(this.timer)
  }
}
</script>

<style lang="scss" scoped>
.imgItem {
  width: 100%;
  background: #fff;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
  }
}
</style>
