<template>
    <div class="content">
        <div class="menu">
            <input type="text" placeholder="输入关键词：多个需求可用|划分" v-model.lazy="query.tag" @keyup.enter="getSelectedSetuData">
            <label for="picType">R18</label><input type="checkbox" id="picType" v-model="r18">
            <button @click="getSelectedSetuData">搜索</button>
        </div>
        <ImagePageItem :imgList="imgInfoList" @scrollBottom="scrollBottomFn" :maxHeight="windowHeight - 130"></ImagePageItem>
    </div>
</template>

<script>
import ImagePageItem from '@/components/ImagePageItem.vue'
export default {
  components: {
    ImagePageItem
  },
  data () {
    return {
      imgInfoList: [],
      r18: false,
      query: {
        tag: '',
        num: 20
      },
      windowHeight: 0
    }
  },
  created () {
    console.log('组件加载')
    this.getImgeList()
  },
  methods: {
    // 获取图片
    async getImgeList () {
      const { data } = await this.$axios({
        url: 'https://setu-lolicon-app.deno.dev',
        method: 'GET',
        params: { ...this.query, r18: this.r18 ? 1 : 0 }
      })
      this.imgInfoList = [...this.imgInfoList, ...data.data]
      console.log(this.imgInfoList)
    },
    // 滚动条否触底事件处理函数
    scrollBottomFn () {
      console.log('触底')
      this.getImgeList()
    },
    getSelectedSetuData () {
      // 清空旧数据
      this.imgInfoList = []
      // 重新获取图片
      this.getImgeList()
    },
    // 获取窗口宽度
    getWindowHeight () {
      this.windowHeight = window.innerHeight
      console.log(this.windowHeight)
    }
  },
  mounted () {
    this.getWindowHeight()
    window.addEventListener('resize', this.getWindowHeight)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.getWindowHeight)
  }
}
</script>

<style lang="scss" scoped>
.content {
    border: 1px solid #ccc;

    .menu {
        box-sizing: border-box;
        display: flex;
        align-items: center;
        width: 500px;
        height: 50px;
        background: teal;

        input[type="text"] {
            width: 200px;
            height: 10px;
            padding: 10px 20px;
        }

        input[type="checkbox"] {
            zoom: 2;
        }

        label {
            color: red;
            margin-left: 20px;
            font-size: 20px;
        }

        button {
            height: 30px;
            width: 70px;
        }
    }

    @media (max-width:640px) {
        .menu {
            justify-content: center;
            width: 100%;
        }

        label {
            color: red;
            margin-left: 8px !important;
            font-size: 20px;
        }
    }

}
</style>
